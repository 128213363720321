import store from '../store/'

export const entitiesApi = {

    getEntities () {
        return axios.get('/api/entities')
    },

    deleteEntity (entity_id) {
        return axios.delete(`/api/entities/${entity_id}`)
    }

}
