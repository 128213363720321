<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible" id="popup-email">
			<div class="modal-background" @click="this.destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog" v-if="response">
					<div class="popup-body">
                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.method') }}</strong>
                            </div>

                            <div class="column">
                                <strong>{{ response.method }}</strong>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.status') }}</strong>
                            </div>

                            <div class="column">
                                <strong class="tag" :class="getClassByStatus(response.status)">{{ response.status }}</strong>
                                <small class="pl-1" v-if="getStatusMessageByCode(response.status) !== ''">
                                    <a :href="`https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${response.status}`"
                                       target="_blank" rel="noreferrer">
                                        {{ getStatusMessageByCode(response.status) }}
                                    </a>
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.path') }}</strong>
                            </div>

                            <div class="column">
                                <small>
                                    <span class="has-text-grey">{{ formatUrl(response.url)[0] }}</span><b class="redacted-tag" v-html="formatUrl(response.url)[1]"></b><span class="has-text-grey">{{ formatUrl(response.url)[2] }}</span>
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.date') }}</strong>
                            </div>

                            <div class="column">
                                {{ response.created_at }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.execution_time') }}</strong>
                            </div>

                            <div class="column">
                                {{ response.duration.toFixed(3) }} {{ $t('developers.api.log_popup.execution_time_suffix') }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.api.log_popup.identifier') }}</strong>
                            </div>

                            <div class="column">
                                {{ this.$props.id }}
                            </div>
                        </div>

                        <hr>

                        <label>{{ $t('developers.api.log_popup.context') }}</label>

                        <div class="mt-3">
                            <a v-if="response && response.request.parameters === null || response.request.parameters === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.api.log_popup.request_parameters') }}
                            </a>

                            <a href="#" @click.prevent="details.showParameters = !details.showParameters" v-else>
                                <i class="icon">{{ details.showParameters ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.api.log_popup.request_parameters') }}
                            </a>

                            <pre v-if="details.showParameters" v-html="highlightedParameters" class="prism-json"></pre>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.query === null || response.request.query === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.api.log_popup.request_query') }}
                            </a>

                            <a href="#" @click.prevent="details.showQuery = !details.showQuery" v-else>
                                <i class="icon">{{ details.showQuery ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.api.log_popup.request_query') }}
                            </a>

                            <pre v-if="details.showQuery" v-html="highlightedQuery" class="prism-json"></pre>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.body === null || response.request.body === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.api.log_popup.request_body') }}
                            </a>

                            <a href="#" @click.prevent="details.showBody = !details.showBody" v-else>
                                <i class="icon">{{ details.showBody ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.api.log_popup.request_body') }}
                            </a>

                            <pre v-if="details.showBody" v-html="highlightedBody" class="prism-json"></pre>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.request.response === null || response.request.response === '[]'"
                               class="disabled-link">{{ $t('developers.api.log_popup.response_body') }}</a>

                            <a href="#" @click.prevent="details.showResponse = !details.showResponse" v-else>
                                <i class="icon">{{ details.showResponse ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.api.log_popup.response_body') }}
                            </a>

                            <pre v-if="details.showResponse" v-html="highlightedResponse" class="prism-json"></pre>
                        </div>
					</div>

					<div class="popup-footer">
                        <button class="button is-rounded" @click="this.destroy">
                            {{ $t('developers.api.log_popup.close') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import * as Http from '../../../constants/Http'
import Prism from 'prismjs'
import 'prismjs/components/prism-json.min'
import 'prismjs/themes/prism.css'

export default {
    props: {
        id: {
            type: String,
        },
    },

    data() {
        return {
            response: null,
            details: {
                showQuery:      false,
                showParameters: false,
                showBody:       false,
                showResponse:   false
            }
        }
    },

    async mounted() {
        const request = await axios.get(`/api/api-client-logs/${this.$props.id}`)

        if (request.status !== 200) {
            this.destroy()
            return false
        }

        this.response = request.data.data
    },

    computed: {
        highlightedParameters() {
            return this.response ? Prism.highlight(this.prettify(this.response.request.parameters), Prism.languages.json, 'json') : ''
        },
        highlightedQuery() {
            return this.response ? Prism.highlight(this.prettify(this.response.request.query), Prism.languages.json, 'json') : ''
        },
        highlightedBody() {
            return this.response ? Prism.highlight(this.prettify(this.response.request.body), Prism.languages.json, 'json') : ''
        },
        highlightedResponse() {
            return this.response ? Prism.highlight(this.prettify(this.response.request.response), Prism.languages.json, 'json') : ''
        }
    },

    methods: {
        getStatusMessageByCode(code) {
            return Http.STATUS_CODES[code] ? Http.STATUS_CODES[code] : ''
        },
        formatUrl(url) {
            try {
                const parts = new URL(url)
                const params = `${parts.search ? parts.search : ''}`
                let suffix = parts.pathname

                if (suffix.includes('XXXXXXXXXXXX')) {
                    const identifier = parts.pathname.split('XXXXXXXXXXXX')[1].substring(0, 4)
                    suffix = parts.pathname.replace(`XXXXXXXXXXXX${identifier}`, 'XXXXXXXXXXXX')
                    suffix = suffix.replace('XXXXXXXXXXXX', `<div class="tag primary">XXXXXXXXXXXX${identifier}</div>`)
                }

                return [
                    parts.hostname ?? parts.host,
                    suffix,
                    params
                ]
            } catch (e) {
                return [
                    url,
                    '',
                    ''
                ]
            }
        },
        prettify(data) {
            try {
                return JSON.stringify(JSON.parse(data), null, 4)
            } catch (e) {
                return data
            }
        },
        getClassByStatus(type) {
            if (type >= 200 && type < 300) {
                return 'is-success'
            } else if (type >= 400 && type < 500) {
                return 'is-warning'
            } else if (type >= 500 && type < 600) {
                return 'is-danger'
            }

            return 'is-light'
        },
    }
}
</script>

<style scoped>
@import 'prismjs/themes/prism.min.css';

.prism-json {
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    padding: 1em;
    background-color: #f5f5f5;
    border-radius: 0.313rem;
}
</style>
