<template>
    <div>
        <div id="account-nav">
            <div class="container">
                <div class="controls">
                    <div id="language-switch">
                        <div v-for="locale in locales"
                             :key="locale.value"
                             @click="setLocale(locale.value)"
                             :class="{ 'selected': selectedLocale === locale.value }">
                            {{ locale.name }}
                        </div>
                    </div>
                    <div id="company-switch" v-if="this.$store.getters['user/allCompanies'].length > 1">
                        <i class="icon">supervised_user_circle</i>
                        <select v-model="selectedCompany">
                            <option disabled>{{ $t('navigation.company_select') }}</option>
                            <option v-for="company in this.$store.getters['user/allCompanies']" :key="company.id"
                                    :value="company.id">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                    <div id="personal-nav">
                        <a href="https://gifty.nl/nl/contact" target="_blank" rel="noopener">
                            <i class="icon selected">support</i>
                            {{ $t('navigation.support') }}
                        </a>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin'}">
                            {{ $t('navigation.admin') }}
                        </router-link>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin.marketing'}">
                            {{ $t('navigation.admin_marketing') }}
                        </router-link>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin.accounting'}">
                            {{ $t('navigation.admin_accounting') }}
                        </router-link>

                        <router-link :to="{name: 'settings.account'}">{{ $t('navigation.my_account') }}</router-link>

                        <a @click="this.logout">{{ $t('navigation.logout') }}</a>
                    </div>
                </div>
            </div>
        </div>

        <nav :class="['nav', { 'nav-builder': showNavbarBuilder }]">
            <div class="container">
                <div class="nav-bar">
                    <div class="nav-brand">
                        <router-link :to="{name: 'home'}" exact>
                            <img v-if="isHolidaySeason" src="../../../images/logo-christmas.svg" width="100" height="40">
                            <img v-else src="../../../images/logo.svg" width="100" height="40">
                        </router-link>

                        <a role="button" class="navbar-burger" :class="{'is-active': navActive}" aria-label="menu"
                           @click="navActive = !navActive">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <ul class="nav-menu" v-bind:class="{'is-active': navActive}" ref="navMenu">
                        <li>
                            <div class="nav-item">
                                <router-link :to="{ name: 'home'}" exact>
                                    {{ $t(redeemNavText) }}
                                </router-link>
                            </div>
                        </li>

                        <li
                            v-for="item in menuItems"
                            v-if="allowedRoute(item.route_name)"
                            :key="item.route_name"
                            :class="{'nav-item-mobile': item.main === false}"
                        >
                            <menu-item-dropdown
                                v-if="item.submenu_items"
                                :title="item.title"
                                :menu-item="item"
                                :sub-menu-items="item.submenu_items"
                                @dropdown-opened="setScrollToTop"
                            ></menu-item-dropdown>

                            <div v-else class="nav-item">
                                <router-link
                                    :to="getRoute(item.route_name)">
                                    {{ $t(item.title) }}
                                </router-link>
                            </div>
                        </li>

                        <li class="nav-item-mobile">
                            <div class="nav-item is-mobile">
                                <a href="/logout" @click.prevent="logout">{{ $t('navigation.logout') }}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {allowedRoute, getRoute, menuItems} from '../../menu'
import MenuItemDropdown from '../MenuItemDropdown'
import i18n from '../../utils/i18n'

export default {
    components: {
        MenuItemDropdown
    },
    data() {
        return {
            selectedCompany: null,
            selectedLocale: this.$i18n.locale,
            navActive: false,
            menuItems,
            getRoute,
            allowedRoute,
        }
    },

    watch: {
        $route() {
            this.navActive = false
        },
        storeActiveCompanyId(companyId) {
            this.selectedCompany = companyId
        },
        selectedCompany(companyId, oldValue) {
            if (oldValue === null || companyId === oldValue) {
                return
            }

            this.$store.dispatch('user/setCompanyActive', companyId)
        },
        selectedLocale(lang) {
            localStorage.setItem('locale', lang)
            i18n.locale = lang
            document.querySelector('html').setAttribute('lang', lang)

            axios.patch(`/api/users/${this.$store.getters['user/id']}`, {
                locale: lang
            })
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'user/hasPermission',
            storeActiveCompanyId: 'user/activeCompanyId',
            locales: 'application/availableLocalesTranslated',
        }),
        isHolidaySeason() {
            const date = new Date()
            const month = date.getMonth()
            const day = date.getDate()

            // Return true between December 6th and January 3rd (inclusive)
            return (month === 11 && day >= 6) || (month === 0 && day <= 3)
        },
        redeemNavText() {
            if (
                this.hasPermission('giftcard_redeem') &&
                this.hasPermission('giftcard_issue') === false
            ) {
                return 'navigation.giftcard_redeem'
            }

            if (
                this.hasPermission('giftcard_issue') &&
                this.hasPermission('giftcard_redeem') === false
            ) {
                return 'navigation.giftcard_issue'
            }

            return 'navigation.giftcard_redeem_or_issue'
        },
        showNavbarBuilder() {
            const builderPages = [
                'settings.giftcard.builder',
                'settings.module.packages.create',
                'settings.module.packages.edit',
            ]
            return builderPages.includes(this.$route.name)
        },
    },

    methods: {
        logout() {
            this.$store.dispatch('user/logout')
                .then(() => {
                    this.$router.push({name: 'auth.login'})
                })
        },
        setLocale(locale) {
            this.selectedLocale = locale
        },
        setScrollToTop() {
            this.$refs.navMenu.scrollTop = 0
        }
    },

    mounted() {
        this.selectedCompany = this.storeActiveCompanyId

        if (
            localStorage.hasOwnProperty('impersonatePreferredCompany') &&
            localStorage.getItem('impersonatePreferredCompany') !== null &&
            this.$store.getters['user/isGiftyAdmin']
        ) {
            this.$store.dispatch('user/setCompanyActive', localStorage.getItem('impersonatePreferredCompany'))
                .then(() => {
                    localStorage.removeItem('impersonatePreferredCompany')
                })
        }
    },
}
</script>


<style scoped lang="scss">
@import "../../../sass/bulma";
@import "../../../sass/icons";

#account-nav {
    background: #FFFFFF;

    @media screen and (max-width: 1023px) {
        display: none;
    }

    .container {
        border-bottom: 1px solid #F1F5F8;
    }

    .controls {
        display: flex;

        i.icon {
            color: #1f1f1f;
            font-size: 1.1rem;
            vertical-align: text-bottom;
        }

        #language-switch {
            > div {
                color: #1F1F1F;
                cursor: pointer;
                font-size: 0.8rem;
                line-height: 1;
                margin: 0.94rem 0;
                padding: 0 .4rem;
                display: inline-block;
                border-right: 2px solid #EFEFEF;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border-right: 0;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .selected {
                font-weight: 600;
            }
        }

        #company-switch {
            padding-left: 3px;
            margin: 8px 0;
            flex: 0;
            display: flex;
            align-items: center;

            select {
                font-size: 0.8rem;
                max-width: 160px;
                padding: 3px 23px 3px 3px;
                border: 0;
                background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M24 24H0V0h24v24z' fill='none'/><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/></svg>") no-repeat center right;
                appearance: none;

                &:after {
                    font-family: 'Material Icons Outlined';
                    content: "expand_more";
                }
            }
        }

        #personal-nav {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                color: #1F1F1F;
                font-size: .8rem;
                padding: 8px;

                &:hover {
                    text-decoration: underline;
                }

                &:last-child {
                    padding-right: 6px;
                }
            }
        }
    }
}

.nav {
    background: #FFFFFF;
    border-bottom: 1px solid $grey-white;
    z-index: 120;
    top: 0;

    $navbarHeight: 4.375rem;
    $navbarPadding: 1.5625rem;

    &.nav-builder {

        // Note: mobile only overwrite
        @media screen and (max-width: 1023px) {
            width: 100%;
            position: fixed;
            background: transparent;
            border-color: transparent;

            .nav-brand {
                border-color: transparent;

                > a > img {
                    visibility: hidden;
                }
            }

            .navbar-burger {
                background-color: $primary !important;
                border-radius: 50% !important;
                opacity: .65;
                scale: .8;

                // Span
                span {
                    color: #fff;
                }
            }
        }
    }

    .nav-bar {
        display: flex;
        justify-content: space-between;
    }

    .nav-brand {
        flex: 1;
        height: $navbarHeight;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey-white;

        @media screen and (min-width: 1024px) {
            flex-grow: 0;
            border-color: #FFFFFF;
        }

        > a {
            font-size: 0;
            padding: 1.2rem 0;

            img {
                max-height: none;
                max-width: 5.2rem;
                margin-left: $navbarPadding;

                @media screen and (min-width: 1024px) {
                    margin: 0;
                }
            }
        }

        .navbar-burger {
            outline: 0;
            border: 0;
            box-shadow: 0 0 0;
            background: transparent;
            margin-right: .6rem;

            &:focus, &:hover {
                outline: 0;
                border: 0;
                box-shadow: 0 0 0;
                background: transparent;
            }
        }
    }

    &::v-deep {
        ul.nav-menu,
        ul.nav-dropdown {
            list-style-type: none;
            flex: 1;
            width: 100%;
            height: calc(100vh - $navbarHeight);
            background: #FFFFFF;
            position: fixed;
            top: $navbarHeight;
            z-index: 121;
            transform: translateX(100%);
            transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
            visibility: hidden;
            opacity: 0;
            overflow-y: scroll;
            scrollbar-width: none;

            @media screen and (min-width: 1024px) {
                width: auto;
                height: auto;
                display: flex;
                transform: none;
                transition: none;
                overflow: hidden;
                position: initial;
                justify-content: flex-end;
                visibility: visible;
                opacity: 1;
                align-items: flex-end;
            }

            &.is-active {
                transform: translateX(0);
                visibility: visible;
                opacity: 1;
                transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
            }

            li {
                font-size: 0;
                display: flex;

                @media screen and (min-width: 1024px) {
                    padding-left: 2rem;
                }

                &.nav-item-mobile {

                    @media screen and (min-width: 1024px) {
                        display: none;
                    }
                }

                .nav-item {
                    flex: 1;

                    > a {
                        color: $text;
                        font-size: 1.0625rem;
                        font-weight: 700;
                        white-space: nowrap;
                        text-decoration: none;
                        padding: 1rem $navbarPadding;
                        display: block;
                        position: relative;
                        border-bottom: .1rem solid #f9f9f9;

                        @media screen and (min-width: 1024px) {
                            font-weight: 400;
                            font-size: 1rem;
                            padding: 1.3rem 0;
                            border-bottom: 3px solid transparent;
                        }

                        &.is-active {

                            @media screen and (min-width: 1024px) {
                                border-color: $primary;
                            }
                        }
                    }

                    &.has-dropdown > a:after,
                    &.has-return > a:after {
                        @extend .icon;
                        font-weight: 700;
                        font-size: 1rem;
                        margin: auto 0;
                        width: 1rem;
                        height: 1rem;
                        position: absolute;
                        top: 0;
                        bottom: 0;

                        @media screen and (min-width: 1024px) {
                            display: none;
                        }
                    }

                    &.has-dropdown > a {
                        padding-right: calc($navbarPadding + 1.5rem);

                        @media screen and (min-width: 1024px) {
                            padding-right: 0;
                        }

                        &:after {
                            content: "arrow_forward_ios";
                            right: $navbarPadding;
                        }
                    }

                    &.has-return > a {
                        padding-left: calc($navbarPadding + 1.5rem);

                        @media screen and (min-width: 1024px) {
                            padding-left: 0;
                        }

                        &:after {
                            content: "arrow_back_ios_new";
                            left: $navbarPadding;
                        }
                    }
                }
            }
        }

        ul.nav-menu {

            &:has(.nav-dropdown.is-active) {
                overflow: hidden;
            }
        }

        ul.nav-dropdown {
            height: 100%;
            top: 0;
            position: fixed;
            z-index: 122;

            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }
}
</style>
