<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <g-form :form="form"
                        action="/api/payment-settings"
                        method="post" ref="form" class="popup-dialog"
                        @success="confirmed">
                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.$t(`payment_settings.method.sepa-direct-debit.title`) }}</h1>
                        <p>{{ this.$t(`payment_settings.method.sepa-direct-debit.description`) }}</p>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body popup-rows">
                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ this.$t(`payment_settings.method.sepa-direct-debit.creditor`) }}</strong>
                            </div>
                            <div class="column">
                                <small>
                                    Gifty B.V.<br/>
                                    Floridalaan 8<br/>
                                    3404WV IJsselstein NL<br/>
                                    NL31GTY695069810000
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ this.$t(`payment_settings.method.sepa-direct-debit.debtor`) }}</strong></div>
                            <div class="column">
                                <small>
                                    {{ this.$store.getters['user/activeCompany'].name }}<br/>
                                    {{ this.$store.getters['user/activeCompany'].street }} {{
                                        this.$store.getters['user/activeCompany'].house_number
                                    }}{{ this.$store.getters['user/activeCompany'].addition }}<br/>
                                    {{ this.$store.getters['user/activeCompany'].zip }}
                                    {{ this.$store.getters['user/activeCompany'].city }}
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ this.$t(`payment_settings.method.sepa-direct-debit.account`) }}</strong>
                            </div>
                            <div class="column">
                                <g-input name="account_iban"
                                         :label="this.$t(`payment_settings.method.sepa-direct-debit.iban`)"
                                         ref="account_iban"
                                         @input="validateIBAN"/>
                                <g-input name="account_bic"
                                         :label="this.$t(`payment_settings.method.sepa-direct-debit.bic`)"
                                         ref="account_bic"/>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth"></div>
                            <div class="column">
                                <g-switch name="accept_agreement" :label="this.$t(`agreement_dialog.confirm`)"
                                          :subtitle="this.$t(`payment_settings.method.sepa-direct-debit.agreement`)"/>
                            </div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" role="button">{{ this.$t('agreement_dialog.deny') }}</a>
                        <button
                            :disabled="disabled"
                            class="button is-rounded is-success"
                            :class="{'is-loading': this.isLoading}">
                            {{ this.$t('agreement_dialog.confirm') }}
                        </button>
                    </div>
                </g-form>
            </div>
        </div>
    </transition>
</template>

<script>

import {isValidBIC, isValidIBAN} from "ibantools";
import {ibanToBic} from "iban-to-bic";

export default {
    props: {
        paymentMethodId: String,
    },
    data() {
        return {
            isLoading: false,
            form: new Form({
                payment_method_id: null,
                account_iban: '',
                account_bic: '',
                accept_agreement: 0,
            }),
        }
    },
    computed: {
        ibanWithoutSpaces() {
            return this.form.account_iban.replace(/\s/g, '');
        },
        disabled() {
            return this.form.accept_agreement !== 1 ||
                isValidIBAN(this.ibanWithoutSpaces) === false ||
                isValidBIC(this.form.account_bic) === false
        },
    },
    methods: {
        validateIBAN($event) {
            this.form.account_iban = $event.target.value.toUpperCase()

            if (isValidIBAN(this.ibanWithoutSpaces)) {
                this.form.account_bic = ibanToBic(this.ibanWithoutSpaces)
            }
        },
        confirmed() {
            this.$emit('acceptanceSuccessful')

            this.destroy(false)
        },
    },
    mounted() {
        this.form.payment_method_id = this.paymentMethodId;
    },
}
</script>
