<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('locations.popup.title') }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body" v-if="needsReplacementLocation && otherLocations.length >= 1">
                        <b>{{ $t('locations.popup.replacement') }}</b>

                        <div class="form">
                            <div class="field has-addons mt-2">
                                <b-select placeholder="Selecteer een vestiging.." v-model="move_blanco_giftcards_to">
                                    <option :key="location.id" :value="location.id" v-for="location in otherLocations">
                                        {{ location.name }}
                                    </option>
                                </b-select>
                            </div>
                        </div>
                    </div>

                    <div class="popup-body" v-else>
                        <i18n path="locations.popup.description_no_replacement" tag="p"
                              v-if="needsReplacementLocation && otherLocations.length < 1">
                            <template v-slot:amount>
                                <b>{{ blanco_giftcards_count }}</b>
                            </template>
                        </i18n>

                        <i18n path="locations.popup.description_replacement" tag="p" v-else-if="needsReplacementLocation">
                            <template v-slot:amount>
                                <b>{{ blanco_giftcards_count }}</b>
                            </template>
                        </i18n>

                        <span v-else>{{ $t('locations.popup.description') }}</span>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" role="button">{{ this.$t('locations.popup.cancel') }}</a>

                        <button @click="confirmation" :disabled="move_blanco_giftcards_to === null"
                                class="button is-rounded is-success" :class="{ 'is-loading': this.isLoading }"
                                v-if="needsReplacementLocation && otherLocations.length >= 1">
                            {{ this.$t('locations.popup.move') }}
                        </button>

                        <button @click="confirmation" class="button is-rounded is-success"
                                :class="{ 'is-loading': this.isLoading }" v-else-if="needsReplacementLocation === false">
                            {{ this.$t('locations.popup.delete')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            isLoading: false,
            move_blanco_giftcards_to: null
        }
    },

    computed: {
        ...mapGetters({
            locations:       'locations/all',
            currentLocation: 'locations/getById'
        }),

        needsReplacementLocation () {
            return this.blanco_giftcards_count >= 1
        },

        blanco_giftcards_count () {
            return this.locations.length && typeof this.locations.find(lc => lc.id === this.id) !== 'undefined'
                ? this.locations.find(location => location.id === this.id).blanco_giftcards_count
                : 0
        },

        otherLocations () {
            let otherLocations = this.locations.length && typeof this.locations.find(lc => lc.id === this.id) !== 'undefined'
                ? this.locations.filter(location => location.id !== this.id)
                : []

            return otherLocations.map((location) => {
                return {
                    name: `${location.street} ${location.house_number}${location.addition ? location.addition : ''}, ${location.zip} ${location.city}`,
                    id: location.id
                }
            })
        },
    },

    mounted() {
        this.$store.dispatch('locations/loadAll')
    },

    methods: {
        async confirmation () {
            this.isLoading = true

            let config = {
                method: 'DELETE',
                url: `/api/locations/${this.id}`,
                data: {}
            }

            // Change the method and data if a replacement location needs to be provided
            if (this.needsReplacementLocation) {
                config = {
                    method: 'POST',
                    url: `/api/locations/${this.id}/delete-move-stock`,
                    data: {
                        move_blanco_giftcards_to: this.move_blanco_giftcards_to
                    }
                }
            }

            await axios(config).then((data) => {
                this.$emit('locationDeleted', data)
                this.destroy(false)
            }).catch((data) => {
                this.$emit('locationDeletionFailed', data)
                this.$buefy.toast.open(this.$t('notice.error'))
            }).finally(() => {
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="scss">
.popup-body {
    ul {
        list-style-type: initial;
        padding-left: 2em;
        margin: 1em 0;
    }
}
</style>
