<template>
    <preview v-if="isLoaded && library.cdn"
             :form="form"
             :fonts="options.fonts"
             :package="title"
             :show-package-value="showValue"
             :amount="value"
             :front-only="false"
             :cdn="library.cdn"
             :hide-title="hideTitle"
             :hide-switch-buttons="hideSwitchButtons"
             :message="message"
             :card-side="cardSide"
             ref="preview"/>
</template>

<script>
import Preview from './Design/Builder/Preview'
import Vue from 'vue'
import find from 'lodash/find'
import BackgroundPattern from './Design/Builder/BackgroundPattern'

export default {
    name: 'GiftcardPreview',
    components: {Preview},
    props: {
        title: {
            type: String,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideSwitchButtons: {
            type: Boolean,
            default: false,
        },
        showValue: {
            type: [Boolean, Number],
        },
        value: {
            type: Number,
        },
        cardSide: {
            type: String,
            default: 'front',
        },
        message: {
            type: String,
        }
    },
    data() {
        return {
            isLoaded: false,
            newForm: {},
            library: {
                cdn: '',
                images: [],
                patterns: [],
                custom: [],
            },
            form: new Form({
                address: '',
                background_type: '',
                background_value: '',
                background_image: '',
                background_custom: '',
                background_pattern: '',
                background_pattern_image: '',
                background_color: '#EEEEEE',
                branche_id: '',
                color: '',
                color_suggestions: '',
                fontgroup_id: '',
                fonts: '',
                logo: '',
                template_id: '',
                template: '',
                pattern_primary: '',
                pattern_secondary: '',
                ratio: 1.4192439862542956,
            }),
            options: {
                background: {
                    image: 'Afbeelding',
                    pattern: 'Motief',
                    color: 'Kleur',
                },
                branches: [],
                fonts: [],
                templates: [],
                cropper: {
                    logo: {fillColor: '#FFFFFF', width: 800, height: 400},
                    background: {fillColor: '#FFFFFF', width: 2478, height: 1747}
                }
            },
        }
    },
    watch: {
        title() {
            if (this.$refs.preview) {
                this.$refs.preview.redraw()
            }
        },
        showValue() {
            if (this.$refs.preview) {
                this.$refs.preview.redraw()
            }
        },
        amount() {
            if (this.$refs.preview) {
                this.$refs.preview.redraw()
            }
        }
    },
    methods: {

        /**
         * Initialise Builder's component responsible for generating the pattern SVG
         *
         * @url propsData      https://vuejs.org/v2/api/#propsData
         * @url ... Spread syntax  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
         *
         * @var {BackgroundPattern} bg
         * @param {object} pattern
         */
        setBackgroundPatternImage(pattern) {
            // Borrow functions from component in Builder
            const bg = new Vue({

                // Insert component - See @url
                ...BackgroundPattern,

                // Adding props - See @url
                propsData: {
                    name: 'ghostComponent',
                    cropperOptions: {fillColor: '#fff', width: 2478, height: 1747},
                    library: [],
                    type: 'pattern',
                    palette: [],
                    primary: pattern.primary,
                    secondary: pattern.secondary,
                }
            })

            // Request pattern source
            bg.requestImage(pattern.url, false).then(rawValue => {

                // Create object url from Blob with colors in the source code
                this.form.background_pattern_image = URL.createObjectURL(bg.parseBlob(rawValue))
            })
        },
    },
    mounted() {
        axios.get('/api/builder').then(async (response) => {
            const data = response.data.data
            this.library = data.library
            this.options.branches = data.options.branches
            this.options.fonts = data.options.fonts
            this.options.templates = data.options.templates

            for (const key in data.design) {
                if (this.form[key] === undefined) continue

                this.form[key] = data.design[key]
            }

            this.form.template = find(this.options.templates, {id: this.form.template_id})
            this.form.fonts = find(this.options.fonts, {id: this.form.fontgroup_id})

            switch (data.design.background_type) {
                case 'image':
                    Vue.set(this.form, 'background_image', data.design.background_url)
                    break
                case 'pattern':

                    const pattern = JSON.parse(this.form.background_value) // {"branche_id":2,"svg":77,"primary":"#79700a","secondary":"#ecdc2c"}

                    this.form.pattern_primary = pattern.primary
                    this.form.pattern_secondary = pattern.secondary
                    pattern.type = 'pattern'
                    pattern.id = pattern.svg
                    pattern.url = data.design.background_url

                    this.form.background_pattern = pattern
                    this.setBackgroundPatternImage(pattern)

                    break
                case 'color':
                    this.form.background_color = this.form.background_value
                    break
            }

            if (data.design.logo !== 'placeholder') {
                this.form.logo = data.design.logo_url
            }

            if (this.form.template.name === 'Custom') {
                Vue.set(this.form, 'background_custom', this.form.background_image)
            }

            this.isLoaded = true
            this.$nextTick(() => {
                this.$refs.preview.loadAllFonts(this.options.fonts)
                this.$refs.preview.runFormHandler(this.form, this.form)
            })
        })
    },
}
</script>
